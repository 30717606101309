import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

const ClientReviewModal = ({ isOpen, onClose, client, onSave, clientId }) => {
  const [editedClient, setEditedClient] = useState({ ...client });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize local state when modal opens.
  useEffect(() => {
    if (isOpen && client && Object.keys(client).length > 0) {
      setEditedClient({ ...client });
    }
  }, [isOpen, client]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedClient((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!editedClient || !editedClient.id) return;
    setIsSubmitting(true);
    try {
      console.log("Original client object:", client);
      console.log("Edited client object:", editedClient);
  
      // Create a copy of the updated client data
      const updatedClient = { ...editedClient };
  
      // If last name is blank or only whitespace, default it to a dash ("-")
      if (!updatedClient.last_name || updatedClient.last_name.trim() === "") {
        updatedClient.last_name = "-";
      }
  
      if (client.tutorcruncher_id) {
        // Build the payload for TutorCruncher update.
        const tutorPayload = {
          user: {
            email: client.email, // Use the original email as the unique identifier.
            first_name: updatedClient.first_name,
            last_name: updatedClient.last_name,
          },
        };
  
        console.log("Payload sent to TutorCruncher:", tutorPayload);
  
        // Call your TutorCruncher update endpoint.
        const tutorResponse = await axios.post(
          `/api/tutorcruncher/update-client`,
          tutorPayload
        );
        console.log("Response from TutorCruncher update:", tutorResponse.data);
  
        // Remove the email field from the payload sent to the local DB update.
        const { email, ...fieldsWithoutEmail } = updatedClient;
        const localResponse = await axios.put(
          `/api/clients/${clientId}/details`,
          fieldsWithoutEmail
        );
        console.log("Response from local DB update:", localResponse.data);
  
        if (localResponse.status === 200) {
          onSave(localResponse.data);
          onClose();
        } else {
          console.error("Error updating client in local DB:", localResponse.data);
        }
      } else {
        // Client is not linked to TutorCruncher; update normally (including email).
        const response = await axios.put(
          `/api/clients/${clientId}/details`,
          updatedClient
        );
        console.log("Response from local DB update:", response.data);
        if (response.status === 200) {
          onSave(response.data);
          onClose();
        } else {
          console.error("Error updating client in local DB:", response.data);
        }
      }
    } catch (error) {
      console.error("Error updating client:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Review & Update Client Details
                </Dialog.Title>
                <div className="mt-4 space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={editedClient.first_name || ''}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={editedClient.last_name || ''}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={editedClient.email || ''}
                      onChange={handleChange}
                      className="mt-1 px-3 py-2 border rounded-md w-full"
                      // Disable editing if client is linked to TutorCruncher.
                      disabled={!!client.tutorcruncher_id}
                    />
                  </div>
                  <div>
  <label className="block text-sm font-medium text-gray-700">Country</label>
  <input
    type="text"
    name="country"
    value={editedClient.country || 'Unknown'} // Default value if missing
    disabled // Makes it read-only
    className="mt-1 px-3 py-2 border rounded-md w-full bg-gray-100 text-gray-500 cursor-not-allowed"
  />
</div>

                </div>
                <div className="mt-6 flex justify-end gap-4">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 rounded-md hover:bg-blue-200"
                    onClick={handleSave}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Saving...' : 'Save & Proceed'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ClientReviewModal;
